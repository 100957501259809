import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function Ecommerce({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('Ecommerce');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>Ecommerce</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default Ecommerce;

const DATA = [
    {
        title: 'What does the Ecommerce sell?',
        content: 'You will find marketing services, auditing services, liquidity-locking, fundraising, and professional consulting services on this platform'
    },
    {
        title: 'How does the payment works?',
        content: 'You will also be able to use cryptocurrency or fiat to instantly pay for the service on our website by connecting to your wallet or by sending directly to the Ecommerce wallet'
    },
    {
        title: 'Do HedgePay offer CEX Listing Services?',
        content: 'Yes, HedgePay does offers listing with CEX'
    },
    {
        title: 'What are the Ecommerce default payment?',
        content: 'Every services in the Ecommerce will be converted to USDC by default'
    },
    {
        title: 'Can we get a refund if we cancel an Order?',
        content: 'You can <a href="/">contact us</a> in case of cancellations and returns.'
    },
    {
        title: 'How do i get discount from Ecommerce?',
        content: 'Receive an extra 5% discount on selected services by entering the referral code. Referral code can only be obtain from a HedgePay Listing Officer'
    }
]