import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";

import General from '../../components/faq/General';
import Ecommerce from '../../components/faq/Ecommerce';
import Rewards from '../../components/faq/Rewards';
import HPAD from '../../components/faq/HPAD';
import AuditKYC from '../../components/faq/AuditKYC';
import HedgeTalk from '../../components/faq/HedgeTalk';
import MarketMaker from '../../components/faq/MarketMaker';
import Partnership from '../../components/faq/Partnership';

function FAQ() {
  const [page, setPage] = useState('General')
  return (
    <Layout page="Faq" pageInner={page} pageShow={(e) => setPage(e)}>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="FAQ" />
      <Container fluid='lg' className="py-md-5 px-xl-4 py-4">
        {page === 'General' && <General pageInfo={(e) => setPage(e)} />}
        {page === 'Ecommerce' && <Ecommerce pageInfo={(e) => setPage(e)} />}
        {page === 'Rewards' && <Rewards pageInfo={(e) => setPage(e)} />}
        {page === 'HPAD' && <HPAD pageInfo={(e) => setPage(e)} />}
        {page === 'AuditKYC' && <AuditKYC pageInfo={(e) => setPage(e)} />}
        {page === 'HedgeTalk' && <HedgeTalk pageInfo={(e) => setPage(e)} />}
        {page === 'MarketMaker' && <MarketMaker pageInfo={(e) => setPage(e)} />}
        {page === 'Partnership' && <Partnership pageInfo={(e) => setPage(e)} />}
      </Container>
    </Layout>
  );
}

export default FAQ;
