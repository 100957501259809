import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function AuditKYC({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('AuditKYC');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>Audit & KYC</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default AuditKYC;

const DATA = [
    {
        title: 'Is HedgePay Audited?',
        content: 'Yes. HedgePay is audited by our <a href="/">inhouse audit</a>, <a href="/">CERTIK</a>, <a href="/">Dessert Finance</a> and <a href="/">SpyWolf</a>'
    },
    {
        title: 'Where can I apply for KYC & Audit services?',
        content: 'You may apply HedgePay KYC & Audit by <a href="/contact">contacting us</a>. '
    },
    {
        title: 'Do i get an embed badge after completing Audit & KYC?',
        content: 'Yes. An embed badge link will be sent after completing the procedures and results will be uploaded on leaderboard'
    },
    {
        title: 'Where is my audit report?',
        content: 'Audit report can be found at HedgePay <a href="/">Github</a> and <a href="/">Leaderboard</a>'
    }
]